/* global localStorage */

import getCartProductQuantity from './getCartProductQuantity'

const setProductQuantity = ({ state, action }) => {
  if (!state.cartProductList) {
    // eslint-disable-next-line no-console
    console.error('Undefined "cart" in state', state)
  }

  // eslint-disable-next-line no-case-declarations
  const index = state.cartProductList.findIndex((item) => item.productId === action.payload.productId)

  if (index === -1) {
    return state
  }

  // eslint-disable-next-line no-case-declarations
  const cartProductList = [...state.cartProductList]

  cartProductList[index] = {
    productId: cartProductList[index].productId,
    productName: cartProductList[index].productName,
    productSize: cartProductList[index].productSize,
    productImage: cartProductList[index].productImage,
    productWeight: cartProductList[index].productWeight,
    productPrice: cartProductList[index].productPrice,
    price: cartProductList[index].price,
    quantity: parseInt(action.payload.quantity, 10),
  }

  localStorage.setItem('cartProductList', JSON.stringify(cartProductList))

  return {
    ...state,
    cartProductList,
    cartProductQuantity: getCartProductQuantity(cartProductList),
  }
}

export default setProductQuantity
