import React from 'react'
import Link from 'components/foundations/Link/Link'
import { useCartContext } from 'context/CartContext'
import CartIcon from 'icons/header-shopping-bag.svg'
import { Badge } from './Cart.styles'

export default function Cart() {
  const [{ cartProductQuantity }] = useCartContext()

  return (
    <Link to="/cart">
      <Badge badgeContent={cartProductQuantity} color="secondary">
        <CartIcon />
      </Badge>
    </Link>
  )
}
