/* eslint-disable no-nested-ternary */
import React from 'react'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { AiOutlineMenu } from 'react-icons/ai'
import { navigate } from 'gatsby'

import Button from 'components/foundations/Button/Button'
import MenuLinks from './MenuLinks'

export default function NavigationMobile() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const onClickCategory = (slug) => {
    setIsDrawerOpen(false)
    navigate(slug)
  }

  const [open, setOpen] = React.useState(true)

  function isSubMenu(menuToo, subMenuToo) {
    if (subMenuToo && subMenuToo.length > 0) {
      setOpen(!open)
    } else {
      onClickCategory(menuToo.link)
    }
  }

  return (
    <React.Fragment key="Menu">
      <Button variant="buttonless" startIcon={<AiOutlineMenu size="20px" />} onClick={onDrawerOpen} />
      <Drawer anchor="top" open={isDrawerOpen} onClose={onDrawerClose}>
        <List style={{ paddingBottom: '0' }}>
          {MenuLinks.map((menu, index) => (
            <React.Fragment key={menu.name}>
              {index > 0 && <Divider />}
              <ListItem key={menu.name} button onClick={() => isSubMenu(menu, menu.subMenu)}>
                <ListItemText primary={menu.name} />
                {!(menu.subMenu && menu.subMenu.length > 0) ? null : !open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {menu.subMenu && menu.subMenu.length > 0 ? (
                <Collapse in={!open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu.subMenu.map((subLink, i) => (
                      <React.Fragment key={subLink.name}>
                        {i > 0 && <Divider />}
                        <ListItem key={subLink.name} button onClick={() => onClickCategory(subLink.link)}>
                          <ListItemText inset primary={subLink.name} />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  )
}
