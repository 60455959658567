const MenuLinks = [
  {
    id: 'homeMenu',
    link: '/',
    name: 'Home',
    subMenu: [],
  },
  {
    id: 'aboutMenu',
    link: '/chi-siamo',
    name: 'Chi Siamo',
    subMenu: [],
  },
  {
    id: 'servicesMenu',
    name: 'Servizi',
    subMenu: [
      {
        id: 'consultingMenu',
        link: '/consulenza',
        name: 'Consulenza',
      },
      {
        id: 'trainingMenu',
        link: '/formazione',
        name: 'Formazione',
      },
      {
        id: 'engineeringMenu',
        link: '/ingegneria',
        name: 'Ingegneria',
      },
      {
        id: 'medicineMenu',
        link: '/medicina',
        name: 'Medicina',
      },
    ],
  },
  {
    id: 'coursesMenu',
    link: '/corsi',
    name: 'Corsi Online',
    subMenu: [],
  },
  {
    id: 'capMenu',
    link: '/clienti-e-partners',
    name: 'Clienti & Partners',
    subMenu: [],
  },
  {
    id: 'contactsMenu',
    link: '/contatti',
    name: 'Contatti',
    subMenu: [],
  },
]

export default MenuLinks
