/* global localStorage */

import getCartProductQuantity from './reducer/getCartProductQuantity'

const getCartFromLocalStorage = () => {
  try {
    const cartProductList = JSON.parse(localStorage.getItem('cartProductList'))
    if (Array.isArray(cartProductList)) {
      return {
        cartProductList,
        cartProductQuantity: getCartProductQuantity(cartProductList),
      }
    }

    return { cartProductList: [], cartProductQuantity: 0 }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message)
    return []
  }
}

const initialState = { ...getCartFromLocalStorage() }

export default initialState
