import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'

import { useThemeContext } from 'context/ThemeContext'
import Logo from 'components/elements/Logo/Logo'

import { Toolbar, LogoWrapper } from './Header.styles'
import NavigationMobile from './NavigationMobile'
import NavigationDesktop from './NavigationDesktop'
import Cart from './Cart'

export default function Header() {
  const { isMobile } = useThemeContext()

  return (
    <AppBar position="sticky">
      <header>
        <Container className="">
          <Toolbar>
            {isMobile && <NavigationMobile />}
            <LogoWrapper $isMobile={isMobile}>
              <Logo />
            </LogoWrapper>
            {!isMobile && <NavigationDesktop />}
            <Cart />
          </Toolbar>
        </Container>
      </header>
    </AppBar>
  )
}
