import React from 'react'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const SocialLink = () => (
  <div className="social-link">
    <a href="https://www.facebook.com/ingprogetti/" target="_blank" rel="noopener noreferrer">
      <FaFacebookSquare />
    </a>
    <a href="https://www.instagram.com/ing_group/" target="_blank" rel="noopener noreferrer">
      <FaInstagram />
    </a>
  </div>
)
export default SocialLink
