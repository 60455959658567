/* global localStorage */

import getCartProductQuantity from './getCartProductQuantity'

const removeFromCart = ({ state, action }) => {
  if (!state.cartProductList) {
    // eslint-disable-next-line no-console
    console.error('Undefined "cart" in state', state)
  }

  const cartProductList = state.cartProductList.filter((item) => item.productId !== action.payload.productId)

  localStorage.setItem('cartProductList', JSON.stringify(cartProductList))

  return {
    ...state,
    cartProductList,
    cartProductQuantity: getCartProductQuantity(cartProductList),
  }
}

export default removeFromCart
