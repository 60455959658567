import React from 'react'
import Link from 'components/foundations/Link/Link'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default function Logo() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "logo-ing-group-footer.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoHeader = data.background.childImageSharp.fixed

  return (
    <Link to="/">
      <Img id="logo-header" fixed={logoHeader} />
    </Link>
  )
}
