import styled from 'styled-components'
import theme from 'theme'

// eslint-disable-next-line import/prefer-default-export
export const Footer = styled.footer`
  background-color: ${theme.palette.blueDark};
  color: ${theme.palette.primary.main};
  margin-top: auto;
  padding-top: 40px;
  font-size: 14px;
  text-align: center;
  position: sticky;
  bottom: 0;
  z-index: 0;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    position: relative;
  }
`
