import styled from 'styled-components'
import Container_ from '@material-ui/core/Container'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Container = styled(Container_)`
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
`
