/* global localStorage */

const emptyCart = ({ state }) => {
  if (!state.cartProductList) {
    // eslint-disable-next-line no-console
    console.error('Undefined "cart" in state', state)
  }

  const cartProductList = []

  localStorage.setItem('cartProductList', JSON.stringify(cartProductList))

  return {
    ...state,
    cartProductList,
    cartProductQuantity: 0,
  }
}

export default emptyCart
