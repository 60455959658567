import React from 'react'
import { Link } from 'gatsby-plugin-intl'

export default ({ ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      className: isCurrent ? 'current' : '',
    })}
  />
)
