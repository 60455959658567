import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import theme from 'theme'

export const NavBar = styled(Box)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5vw;
  ${theme.breakpoints.down('lg')} {
    margin-right: 0;
  }
  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`
export const NavBarItem = styled(Box)`
  color: #444444;
  position: relative;
  transition-duration: 0.5s;
  float: left;
  margin: 0;
  width: max-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    color: ${theme.palette.blue};
    cursor: pointer;
  }
  & a {
    line-height: 5.1rem;
    padding: 0 1.4vw;
  }
  & span {
    line-height: 5.1rem;
    padding: 0 1.2rem;
  }
  & a:active {
    color: white;
    background-color: ${theme.palette.blue};
  }
  & span:active {
    color: white;
    background-color: ${theme.palette.blue};
  }
  & :hover .submenu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
`

export const NavBarSub = styled(Box)`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  display: none;
  min-width: 8rem;
  position: absolute;
  transition: all 0.5s ease;
  left: 0;
  top: 5rem;
  &:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const NavBarItemSub = styled(Box)`
  clear: both;
  width: 100%;
  padding: 0;
  border: 1px solid #80808030;
  color: #444444;
  font-size: initial;
  & :hover {
    color: ${theme.palette.blue};
  }
  & a {
    line-height: 3rem;
    padding: 0 1.5rem;
    display: block;
  }
  & :hover .current,
  & .current {
    background-color: white;
    color: ${theme.palette.blue};
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const CustomNavBar = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${theme.spacing(2)}px;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`
