/* global localStorage */

import getCartProductQuantity from './getCartProductQuantity'

const addToCart = ({ state, action }) => {
  if (!state.cartProductList) {
    // eslint-disable-next-line no-console
    console.error('Undefined "cart" in state', state)
  }

  // eslint-disable-next-line no-case-declarations
  const index = state.cartProductList.findIndex((item) => item.productId === action.payload.productId)
  // eslint-disable-next-line no-case-declarations
  const cartProductList = [...state.cartProductList]

  if (index === -1) {
    cartProductList.push({
      productId: action.payload.productId,
      productName: action.payload.productName,
      productSize: action.payload.productSize,
      productImage: action.payload.productImage,
      productWeight: action.payload.productWeight,
      productPrice: action.payload.productPrice,
      price: action.payload.price,
      quantity: action.payload.quantity,
    })
  } else {
    cartProductList[index] = {
      productId: action.payload.productId,
      productName: action.payload.productName,
      productSize: action.payload.productSize,
      productImage: action.payload.productImage,
      productWeight: action.payload.productWeight,
      productPrice: action.payload.productPrice,
      price: action.payload.price,
      quantity: parseInt(state.cartProductList[index].quantity, 10) + parseInt(action.payload.quantity, 10),
    }
  }

  localStorage.setItem('cartProductList', JSON.stringify(cartProductList))

  return {
    ...state,
    cartProductList,
    cartProductQuantity: getCartProductQuantity(cartProductList),
  }
}

export default addToCart
