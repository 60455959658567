import React from 'react'
import NavLink from 'components/foundations/Link/NavLink'
import MenuLinks from './MenuLinks'
import { NavBar, NavBarItem, NavBarSub, NavBarItemSub } from './NavigationDesktop.styles'

export default function NavigationDesktop() {
  return (
    <>
      <NavBar>
        {MenuLinks.map((link) => (
          <NavBarItem key={link.name}>
            {link.link ? (
              <NavLink
                to={link.link}
                className={link.subMenu && link.subMenu.length > 0 ? 'disabledLink' : null}
                aria-haspopup={!!(link.subMenu && link.subMenu.length > 0)}
              >
                {link.name}
              </NavLink>
            ) : (
              <span className="unselect">{link.name}</span>
            )}
            {link.subMenu && link.subMenu.length > 0 ? (
              <NavBarSub aria-label="submenu" className="submenu">
                {link.subMenu.map((subLink) => (
                  <NavBarItemSub key={subLink.name}>
                    <NavLink to={subLink.link}>{subLink.name}</NavLink>
                  </NavBarItemSub>
                ))}
              </NavBarSub>
            ) : null}
          </NavBarItem>
        ))}
      </NavBar>
    </>
  )
}
