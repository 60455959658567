import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
    blue: '#245daa',
    blueDark: '#2e4153',
    blueDarker: '#263849',
    greyLight: '#FAFAFA',
  },
})

export default theme
